@import "variables";
@import "mixins";

* {
	box-sizing : border-box;
}

#admin_box {
	align-items     : flex-start;
	display         : flex;
	font-size       : 30px;
	justify-content : flex-start;
	pointer-events  : none;
	position        : fixed;
	right           : -60px;
	top             : 25px;
	transition      : right 0.25s;
	z-index         : 100000;

	&.open {
		right : 0;
	}

	.icon {
		background                : $color-white;
		border-bottom-left-radius : 10px;
		border-top-left-radius    : 10px;
		box-shadow                : -1px 1px 2px 0 rgba(0, 0, 0, 0.5);
		cursor                    : pointer;
		padding                   : 10px;
		pointer-events            : auto;
		z-index                   : 1;
	}

	.links {
		align-items               : flex-end;
		background                : $color-white;
		border-bottom-left-radius : 10px;
		box-shadow                : -1px 1px 2px 0 transparentize($color-black, .65);
		display                   : flex;
		flex-direction            : column;
		padding                   : 10px;
		pointer-events            : auto;
		width                     : 60px;

		a {
			margin-bottom : 15px;

			&:last-child {
				margin-bottom : 0;
			}
		}
	}
}

html {
	height  : 100%;
	margin  : 0;
	padding : 0;
	width   : 100%;

	body {
		height     : 100%;
		margin     : 0;
		padding    : 0;
		text-align : center;
		width      : 100%;
		@include media-breakpoint-down("lg") {
			&:after {
				backdrop-filter : blur(3px);
				background      : transparentize($color-black, .45);
				content         : "";
				height          : 100vh;
				left            : 0;
				opacity         : 0;
				pointer-events  : none;
				position        : fixed;
				top             : 0;
				transition      : opacity .35s;
				width           : 100vw;
				z-index         : 1499;
			}
			&.menu-open {
				overflow : hidden;

				&:after {
					opacity        : 1;
					pointer-events : all;
				}
			}
		}

		#main {
			background-color : $color-white;
			left             : 0;
			margin           : 0 auto;
			min-height       : 100%;
			position         : relative;
			text-align       : left;
			top              : 0;
			width            : 100%;

			.locale-switcher {
				display      : inline-block;
				margin-right : 50px;
				position     : relative;

				.locale {
					align-items     : center;
					display         : flex;
					flex-direction  : column;
					justify-content : flex-start;

					.locale_text {
						color          : $color-black;
						font-size      : 13px;
						line-height    : 15px;
						margin-top     : 3px;
						text-transform : uppercase;
					}
				}

				.available-locales {
					background     : $color-white;
					box-shadow     : 0 0 6px 3px transparentize($color-black, .85);
					display        : flex;
					left           : 50%;
					opacity        : 0;
					padding        : 5px;
					pointer-events : none;
					position       : absolute;
					top            : 50%;
					transform      : translate(-50%, -50%);
					transition     : opacity $transition-default;
					width          : auto;

					&.active {
						opacity        : 1;
						pointer-events : auto;
					}

					.locale {
						margin-right : 5px;
						padding      : 5px 10px;

						&:last-of-type {
							margin-right : 0;
						}

						&:hover {
							background : $color-pink;

							.locale_text {
								color : $color-white;
							}
						}
					}
				}

				@include media-breakpoint-up("lg") {
					&:hover {
						.available-locales {
							opacity        : 1;
							pointer-events : auto;
						}
					}
				}
			}

			@mixin main-menu() {
				ul {
					display         : flex;
					justify-content : space-between;
					list-style-type : none;
					margin          : 30px 0 0 0;
					padding         : 0;

					.nav-item {
						.nav-link {
							color          : $color-black;
							font-size      : 15px;
							font-weight    : 600;
							position       : relative;
							text-transform : uppercase;
							transition     : .25s ease-in-out;

							&.active {
								&:before {
									background : $color-bordeaux;
									bottom     : 100%;
									content    : "";
									height     : 20px;
									left       : 50%;
									position   : absolute;
									transform  : translateX(-50%);
									width      : 3px;
								}
							}

							&:hover {
								color : $color-bordeaux;
							}
						}
					}
				}
			}
			/* --------------------------------------------------------------- */
			/*
			/* Header
			/*
			/* --------------------------------------------------------------- */
			#entete {
				background : $color-beige;
				box-shadow : 0 0 6px 3px transparentize($color-black, .85);
				left       : 0;
				padding    : 10px 0;
				position   : relative;
				top        : 0;
				z-index    : 1000;
				@include media-breakpoint-down("lg") {
					display : none;
				}

				.booking-link {
					border-radius  : 0;
					font-size      : 18px;
					font-weight    : 400;
					letter-spacing : 2px;
				}

				.facebook {
					align-items     : center;
					color           : #3B5998 !important;
					display         : flex;
					justify-content : flex-start;

					.icon {
						font-size    : 40px;
						margin-right : 10px;
					}

					.text {
						.small, .big {
							display     : block;
							line-height : 20px;
						}

						.small {
							font-size : 10px;
						}

						.big {
							font-size : 19px;
						}
					}
				}

				.logo {
					left     : 0;
					position : relative;
					top      : 0;
				}

				.header-nav {
					@include main-menu;
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/* Menu
			/*
			/* --------------------------------------------------------------- */
			#menu {
				background : $color-beige;
				box-shadow : 0 0 6px 3px transparentize($color-black, .85);
				left       : 0;
				min-height : 30px;
				padding    : 35px 0 20px 0;
				position   : fixed;
				top        : 0;
				width      : 100%;
				z-index    : 500;
				@include media-breakpoint-down("lg") {
					height   : 100px;
					padding  : 10px 0;
					position : sticky;
					top      : 0;
					z-index  : 1500;
				}

				.booking-link {
					border-radius  : 0;
					font-size      : 18px;
					font-weight    : 400;
					letter-spacing : 2px;
					@include media-breakpoint-down("xl") {
						font-size      : 14px;
						letter-spacing : 1px;
						padding        : 10px 15px;
					}
					@include media-breakpoint-down("sm") {
						font-size : 12px;
						padding   : 8px;
					}
				}

				.logo-container {
					position : relative;

					.logo {
						left     : 0;
						position : absolute;
						top      : 0;
						@include media-breakpoint-down("lg") {
							position : relative;
						}

						img {
							max-width : 110px;
							@include media-breakpoint-down("lg") {
								height     : 80px;
								max-width  : 100%;
								object-fit : contain;
							}
						}
					}
				}

				nav.menu {
					@include main-menu;
					@include media-breakpoint-down("lg") {
						background  : $color-beige;
						border-left : 5px solid $color-bordeaux;
						height      : calc(100vh - 100px);
						position    : fixed;
						right       : 0;
						top         : 100px;
						transform   : translateX(101%);
						transition  : transform .35s ease-in-out;
						width       : 35vw;
						&.active {
							transform : translateX(0);
						}
					}
					@include media-breakpoint-down("md") {
						width : 45vw;
					}
					@include media-breakpoint-down("sm") {
						width : 60vw;
					}

					ul {
						justify-content : flex-start;
						margin-top      : 0;
						padding-left    : 50px;
						@include media-breakpoint-down("lg") {
							flex-direction : column;
							max-height     : calc(100vh - 155px);
							overflow-y     : auto;
							padding        : 0;
						}

						.nav-item {
							&:not(:last-of-type) {
								margin-right : 55px;
								@include media-breakpoint-down("xl") {
									margin-right : 20px;
								}
							}

							@include media-breakpoint-down("lg") {
								margin : 15px 10px !important;
							}

							.nav-link {
								font-size : 12px;
								@include media-breakpoint-up("lg") {
									padding-bottom : 0;
								}
								@include media-breakpoint-down("lg") {
									font-size      : 15px;
									letter-spacing : 1px;
								}

								&.active {
									@include media-breakpoint-down("lg") {
										color       : $color-bordeaux;
										font-weight : bold;
									}

									&:before {
										@include media-breakpoint-down("lg") {
											display : none;
										}
									}
								}
							}
						}
					}

					.links {
						align-items     : center;
						bottom          : 0;
						display         : flex;
						justify-content : center;
						padding         : 10px;
						position        : absolute;
						right           : 0;
						width           : 100%;
						@include media-breakpoint-up("lg") {
							display : none;
						}

						a {
							align-items     : center;
							background      : $color-pink;
							border-radius   : 50%;
							color           : $color-white;
							display         : flex;
							font-size       : 18px;
							height          : 35px;
							justify-content : center;
							margin          : 0 15px;
							width           : 35px;
						}
					}
				}

				.locale-switcher {
					z-index : 10;
					@include media-breakpoint-between("lg", "xl") {
						margin-right : 15px;
					}
					@include media-breakpoint-down("sm") {
						margin-right : 30px;
					}

					&:hover {
						.available-locales {
						}
					}
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/* Bloc contact fixed
			/*
			/* --------------------------------------------------------------- */
			#floating_contact {
				$circle-size     : 60px;
				$wrapper-padding : 10px;
				align-items      : flex-end;
				display          : flex;
				flex-direction   : column;
				justify-content  : flex-start;
				pointer-events   : none;
				position         : fixed;
				right            : 10px;
				top              : 130px;
				z-index          : 1500;
				@include media-breakpoint-down("lg") {
					display : none;
				}

				.heading {
					background    : transparentize($color-bordeaux, .2);
					border-radius : 150px;
					color         : $color-white;
					margin-bottom : 20px;
					margin-right  : 30px;
					padding       : 5px 25px;
					position      : relative;
					text-align    : right;
					@include media-breakpoint-down("lg") {
						display : none;
					}

					&:after {
						border-color : transparentize($color-bordeaux, .2) transparent transparent transparent;
						border-style : solid;
						border-width : 23px 0 0 16px;
						content      : "";
						height       : 0;
						position     : absolute;
						right        : 35px;
						top          : 100%;
						width        : 0;
					}

					.font-weight-black {
						display   : block;
						font-size : 21px;
					}

					.text-uppercase {
						display   : block;
						font-size : 17px;
					}
				}

				.link-container {
					margin-bottom  : 10px;
					pointer-events : auto;
					position       : relative;

					.icon {
						align-items     : center;
						background      : $color-pink;
						border-radius   : 50%;
						color           : $color-white;
						cursor          : pointer;
						display         : flex;
						height          : $circle-size;
						justify-content : center;
						position        : relative;
						width           : $circle-size;
						z-index         : 1;

						i {
							font-size : 28px;
						}
					}

					.link-wrapper {
						height         : $circle-size + ($wrapper-padding * 2);
						overflow-x     : hidden;
						pointer-events : none;
						position       : absolute;
						right          : 50%;
						top            : -$wrapper-padding;
						width          : 100vw;

						.link {
							align-items               : center;
							background                : #FFFFFF;
							border-bottom-left-radius : $circle-size /2;
							border-top-left-radius    : $circle-size /2;
							box-shadow                : 0 0 6px 3px rgb(0 0 0 / 15%);
							display                   : flex;
							height                    : $circle-size;
							justify-content           : flex-start;
							opacity                   : 0;
							overflow                  : hidden;
							padding                   : 0 45px 0 25px;
							pointer-events            : auto;
							position                  : absolute;
							right                     : 0;
							top                       : $wrapper-padding;
							transform                 : translateX(100%);
							transition                : transform .25s ease-in-out;
							white-space               : nowrap;
							width                     : auto;
							z-index                   : 0;

							a {
								color       : $color-pink;
								font-size   : 21px;
								font-weight : 700;
							}
						}
					}

					&.active,
					&:hover {
						.link-wrapper {
							.link {
								opacity   : 1;
								transform : translateX(0);
							}
						}
					}
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/*
			/*
			/* --------------------------------------------------------------- */
			#page_header {
				height   : 425px;
				position : relative;
				@include media-breakpoint-down("lg") {
					height : 230px;
				}

				img {
					height     : 100%;
					object-fit : cover;
					width      : 100%;
				}

				.title-overlay {
					align-items     : flex-end;
					display         : flex;
					height          : 100%;
					justify-content : center;
					left            : 0;
					padding-bottom  : 75px;
					position        : absolute;
					top             : 0;
					width           : 100%;

					h1 {
						color       : $color-white;
						font-family : "Parisienne", serif;
						font-size   : 84px;
						margin      : 0;
						position    : relative;
						text-align  : center;
						@include media-breakpoint-down("lg") {
							font-size : 45px;
						}

						&:before {
							background : $color-white;
							bottom     : 100%;
							content    : "";
							height     : 55px;
							left       : 50%;
							position   : absolute;
							transform  : translateX(-50%);
							width      : 2px;
						}
					}
				}

				.booking-overlay {
					align-items     : center;
					display         : flex;
					height          : 100%;
					justify-content : center;
					left            : 0;
					pointer-events  : none;
					position        : absolute;
					top             : 0;
					width           : 100%;
					z-index         : 10;
					@include media-breakpoint-down("lg") {
						//height    : 100vh;
						//left      : 0;
						//position  : fixed;
						//top       : 0;
						//transform : translateX(-95%);
						//width     : 50vw;
						display : none;
					}

					.booking {
						background     : $color-white;
						border         : 1px solid $color-light-gray;
						border-radius  : 4px;
						box-shadow     : -20px 23px 20px transparentize($color-black, .5);
						padding        : 25px;
						pointer-events : all;

						h3 {
							border-bottom  : 1px solid $color-gray;
							margin-bottom  : 10px;
							padding-bottom : 10px;
						}

						.form-group {
							margin-bottom : 15px;

							.group {
								align-items     : flex-start;
								display         : flex;
								flex-direction  : column-reverse;
								justify-content : flex-start;

								label {
									font-weight : bold;
								}

								input {
									display : block;
								}
							}
						}

						.required_fields {
							display : none;
						}
					}
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/* Page
			/*
			/* --------------------------------------------------------------- */
			#page {
				//height     : 1500px;
				left       : 0;
				min-height : 350px;
				position   : relative;
				top        : 0;

				a {
					text-decoration : none;
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/* Pied
			/*
			/* --------------------------------------------------------------- */

			.reservation-footer {
				background : $color-pink;
				padding    : 3rem 0;

				h2{
					display : block;
					margin-bottom : 2.5rem;
				}
				.border-r{
					border-right : 1px solid $color-white;
					@include media-breakpoint-down(md){
						border-right : none;
						border-bottom : 1px solid $color-white;
						padding-bottom : 2rem;
						margin-bottom : 2rem;
					}
				}
				.reservation-container {
					display        : flex;
					flex-direction : column;
					align-items    : center;

					.reservation-title {
						a {
							display : flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							.text {
								color          : $color-white;
								font-size      : 1.5rem;
								font-weight    : 700;
								margin-bottom : 1rem;
								text-transform : uppercase;

								span {
									font-weight : 200;
								}
							}

							.button {
								background     : $color-white;
								color          : $color-pink;
								font-size      : 1.2rem;
								font-weight    : 700;
								padding        : .75rem 1.5rem;
								text-transform : uppercase;

								&:hover {
									background : $color-light-gray;
								}
							}
						}
					}
				}
			}

			#pied {
				position : relative;

				.bloc {
					padding  : 50px 0 40px 0;
					position : relative;

					&:not(.no-line) {
						&:before {
							background : $color-pink;
							content    : "";
							height     : 40px;
							left       : 0;
							position   : absolute;
							top        : 0;
							width      : 1px;
						}
					}

					h3, h4 {
						font-weight    : 400;
						letter-spacing : 2px;
						text-transform : uppercase;
					}

					h3 {
						font-size   : 23px;
						line-height : 19px;
					}

					h4 {
						font-size   : 16px;
						line-height : 19px;
					}

					ul {
						list-style-type : none;
						padding         : 0;

						li {
							&:before {
								color        : $color-pink;
								content      : ">";
								margin-right : 10px;
							}

							&:not(:last-child) {
								margin-bottom : 10px;
							}
						}
					}

					.social-links {
						margin-top : 20px;

						a {
							font-size : 25px;

							&:not(:last-of-type) {
								margin-right : 15px;
							}
						}
					}

					.payment_logo {
						img {
							max-height : 25px;
							width      : auto;

							&:not(:last-of-type) {
								margin-right : 10px;
							}

							&:last-of-type {
								height     : auto;
								margin-top : -4px;
								max-height : none;
								width      : 63px;
							}
						}
					}
				}
			}

			/* --------------------------------------------------------------- */
			/*
			/* Général
			/*
			/* --------------------------------------------------------------- */
			@each $name, $color in $site-colors {
				.bg-#{$name} {
					background : $color !important;
				}
				.hover-bg-#{$name} {
					&:hover {
						background : $color !important;
					}
				}
				.color-#{$name} {
					color : $color !important;
				}
				.hover-color-#{$name} {
					&:hover {
						color : $color !important;
					}
				}
			}

			.top-line {
				padding-top : 70px;
				position    : relative;
				@include media-breakpoint-down("md") {
					padding-top : 50px;
				}

				&.top-line-xl {
					padding-top : 105px;
					@include media-breakpoint-down("md") {
						padding-top : 70px;
					}

					&:before {
						height : 85px;
						@include media-breakpoint-down("md") {
							height : 50px;
						}
					}
				}

				&:before {
					content  : "";
					height   : 50px;
					left     : 0;
					position : absolute;
					top      : 0;
					width    : 2px;
					@include media-breakpoint-down("md") {
						height : 30px;
					}
				}

				&.top-line-right {
					&:before {
						left  : auto;
						right : 0;
					}
				}

				&.top-line-center {
					&:before {
						margin : 0 auto;
						right  : 0;
					}
				}

				@each $name, $color in $site-colors {
					&.top-line-#{$name} {
						&:before {
							background : $color;
						}
					}
				}
			}
		}
	}
}