@import url("https://fonts.googleapis.com/css2?family=Parisienne&family=Raleway:wght@400;500;600;700;900&display=swap");

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";

@import "variables";
@import "mixins";

html {
	body {
		font-family : "Raleway", sans-serif;
		font-size   : $default-font-size;
		font-weight : 400;
		@include media-breakpoint-down("lg") {
			font-size : $default-font-size * .9;
		}
		@include media-breakpoint-down("md") {
			font-size : $default-font-size * .85;
		}
		@include media-breakpoint-down("sm") {
			font-size : $default-font-size  * .8;
		}
		@each $level, $attributes in $title-font-map {
			h#{$level}, .title-#{$level} {
				@each $attribute, $property in $attributes {
					@if $attribute == "line-height" or  $attribute == "font-size" {
						@include media-breakpoint-down("lg") {
							#{$attribute} : $property * .9;
						}
						@include media-breakpoint-down("md") {
							#{$attribute} : $property * .85;
						}
						@include media-breakpoint-down("sm") {
							#{$attribute} : $property * .8;
						}
					}
					#{$attribute} : $property;
				}
			}
		}
		@each $weight, $value in $weights {
			.font-weight-#{$weight} {
				font-weight : $value !important;
			}
		}
		a {
			text-decoration : none;
			transition      : $transition-default;
			img {
				border : none;
			}
		}
		.btn {
			background     : $color-pink;
			border-radius  : 4px;
			box-shadow     : none;
			color          : $color-white;
			font-size      : 14px;
			font-weight    : 700;
			line-height    : 14px;
			outline        : none !important;
			padding        : 12px 40px 12px 20px;
			position       : relative;
			text-transform : uppercase;
			transition     : .25s ease-in-out;
			&:after {
				content    : url(../../images/btn_arrow.svg);
				position   : absolute;
				right      : 15px;
				top        : 50%;
				transform  : translateY(-50%) scale(1.3);
				transition : .25s ease-out;;
			}
			&.no-arrow {
				padding-right : 20px;
				&:after {
					content : none;
				}
			}
			&:focus,
			&:hover {
				box-shadow : 1px 1px 6px 3px transparentize($color-black, .85);
				color      : $color-white;
				&:after {
					right : 10px;
				}
			}
		}
		h2 {
			color       : $color-bordeaux;
			font-family : "Parisienne", serif;
			font-size   : 55px;
			font-weight : 400;
			@include media-breakpoint-down("lg") {
				font-size   : 40px;
				line-height : 45px;
			}
			@include media-breakpoint-down("md") {
				font-size   : 30px;
				line-height : 35px;
			}
			@include media-breakpoint-down("sm") {
				font-size   : 25px;
				line-height : 30px;
			}
			.overline {
				color         : #989696;
				display       : block;
				font-family   : "Raleway", sans-serif;
				font-size     : 20px;
				line-height   : 24px;
				margin-bottom : 10px;
			}
		}
	}
}